import { createApp } from 'vue'
import App from './App.vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import '@popperjs/core/dist/umd/popper.min.js'
import 'jquery/dist/jquery.slim.min.js'

library.add(faEye)

createApp(App)
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount('#app')
