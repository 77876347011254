<template>
  <div class="container my-5">
    <div class="text-center my-3">
      <img :src="`/logo.png`" alt="Logo" style="max-width: 100px" />
      <h4 class="text-center mb-4">Bienvenido</h4>
      <p class="text-center">
        Aquí podrás consultar la información de tus préstamos vigentes.
      </p>
      <p class="text-center" style="font-size: 0.8em">
        El código de cliente lo puedes solicitar con tu cobrador designado.
      </p>
    </div>
    <div class="card shadow">
      <div class="card-header bg-success text-white">
        <h3 class="card-title mb-0">Estado de cuenta cliente</h3>
      </div>
      <div class="card-body">
        <form @submit.prevent="submitForm" class="mb-4">
          <div class="row align-items-end">
            <div class="col-12 col-md-4 mb-3">
              <label class="form-label">Número de documento</label>
              <input type="number" v-model.number="dni" class="form-control" required />
            </div>
            <div class="col-12 col-md-4 mb-3">
              <label class="form-label">Código Cliente</label>
              <input
                type="number"
                v-model.number="codCliente"
                class="form-control"
                required
              />
            </div>
            <div class="col-12 col-md-4 mb-3">
              <button type="submit" class="btn btn-success w-100">Consultar</button>
            </div>
          </div>
        </form>

        <div v-if="isLoading" class="text-center my-5">
          <div class="spinner-border text-success" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <h3 class="card-title mb-0 text-center" v-if="!isLoading && loans.length">
          {{ accountStatusTitle }}
        </h3>
        <!-- ... -->
        <div v-if="!isLoading && loans.length" class="row">
          <div class="col-md-6">
            <div
              class="card my-4"
              :class="{
                'bg-warning text-white':
                  hasBlocks || loans.length >= 2 || isOverdueBlocked,
                'bg-success': !hasBlocks && loans.length < 2 && !isOverdueBlocked,
              }"
            >
              <div
                class="alert"
                role="alert"
                :class="{
                  'alert-warning': hasBlocks || loans.length >= 2 || isOverdueBlocked,
                  'alert-success': !hasBlocks && loans.length < 2 && !isOverdueBlocked,
                }"
              >
                <h5 class="alert-heading">Bloqueos</h5>
                <ul class="mb-0">
                  <li v-if="hasBlocks">
                    El cliente tiene bloqueos por incumplimiento de pago. Fecha de
                    desbloqueo: {{ latestBlock.unblockDate }}
                  </li>
                  <li v-else-if="activeLoans >= 2">
                    Limite de prestamos alcanzado: no puedes tener más de dos (2)
                    préstamos vigentes simultáneamente.
                  </li>
                  <li v-else-if="isOverdueBlocked">
                    Cliente actualmente bloqueado por préstamos en mora.
                  </li>
                  <li v-else>El cliente actualmente no tiene bloqueos</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-6" v-if="activeLoans > 0">
            <div class="card my-4 bg-danger">
              <div class="alert alert-danger" role="alert">
                <h5 class="alert-heading">Pagos en mora</h5>
                <ul class="mb-0">
                  <li>
                    {{ getOverduePaymentsCount().count }} Cuota(s) en mora ({{
                      getOverduePaymentsCount().overdueDays
                    }}
                    días)
                  </li>
                  <li>
                    Total en mora:
                    {{ currencyFormat.format(getOverduePaymentsCount().sum) }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-6" v-if="noLoansFound && !isLoading">
            <div class="card my-4 bg-info">
              <div class="alert alert-info" role="alert">
                <h5 class="alert-heading">Sin prestamos</h5>
                No se encontraron prestamos activos en el momento.
              </div>
            </div>
          </div>
        </div>
        <!-- ... -->
        <div v-if="!isLoading && activeLoans > 0">
          <table
            v-if="!isLoading && activeLoans > 0"
            class="table table-striped table-hover"
          >
            <thead>
              <tr>
                <th colspan="7" class="text-center">PRESTAMOS VIGENTES</th>
              </tr>
            </thead>
            <thead>
              <tr>
                <th class="d-none d-md-table-cell">ID</th>
                <th class="d-none d-md-table-cell">Modalidad</th>
                <th>Fecha</th>
                <th>Monto</th>
                <th>Cuotas</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="loan in filteredLoans" :key="loan.id">
                <td class="d-none d-md-table-cell">{{ loan.id }}</td>
                <td class="d-none d-md-table-cell">{{ loan.modality }}</td>
                <td>{{ loan.loanDate }}</td>
                <td>{{ currencyFormat.format(loan.amount) }}</td>
                <td>{{ getPaidFees(loan.payments) }}</td>
                <td>
                  <button class="btn btn-sm btn-primary" @click="showPayments(loan)">
                    <font-awesome-icon icon="eye" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="alert alert-danger" role="alert" v-if="doestExist && !isLoading">
          Los datos ingresados no corresponden a un cliente registrado.
        </div>
      </div>
    </div>
  </div>
  <div v-if="selectedLoan">
    <div class="modal" tabindex="-1" role="dialog" ref="paymentsModal" id="paymentsModal">
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header bg-secondary text-white">
            <h5 class="modal-title">Pagos del préstamo</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="closePaymentsModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="table-responsive table-wrapper">
              <table class="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>Fecha de vencimiento</th>
                    <th>Valor a pagar</th>
                    <th class="d-none d-md-table-cell">Días transcurridos</th>
                    <th>Estado de la cuota</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="payment in selectedLoan.payments" :key="payment.id">
                    <td>{{ payment.expirationDate }}</td>
                    <td>{{ currencyFormat.format(payment.toPay) }}</td>
                    <td class="d-none d-md-table-cell">
                      {{ getDisplayDaysElapsed(payment) }}
                    </td>
                    <td>
                      <span :class="getLabelClass(payment.state)">{{
                        payment.state
                      }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      dni: "",
      codCliente: "",
      loans: [],
      isLoading: false,
      error: "",
      selectedLoan: null,
      currencyFormat: new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
        minimumFractionDigits: 0,
      }),
      hasBlocks: false, // nueva variable para controlar si el cliente tiene bloqueos
      latestBlock: null, // nueva variable para almacenar el bloqueo con fecha de desbloqueo más reciente
      noLoansFound: false,
      urlApi: process.env.VUE_APP_API_URL,
      urlLogo: process.env.VUE_APP_LOGO_URL,
      activeLoans: 0,
      doestExist: false,
    };
  },
  mounted() {
    if (this.$refs.paymentsModal) {
      this.$refs.paymentsModal.addEventListener("hidden.bs.modal", () => {
        this.selectedLoan = null;
      });
    }
  },
  computed: {
    isOverdueBlocked() {
      const overdueDays = this.getOverduePaymentsCount().overdueDays;
      return overdueDays > 8;
    },
    accountStatusTitle() {
      const title = "ESTADO DE CUENTA - " + this.loans[0]?.client?.name || "";
      return this.titleCase(title);
    },
    filteredLoans() {
      return this.loans.filter((loan) => loan.state === 1);
    },
  },
  methods: {
    async getLoans() {
      this.isLoading = true;
      this.loans = [];
      this.error = "";
      this.hasBlocks = false;
      this.latestBlock = null; // resetear las variables antes de hacer una nueva consulta
      this.noLoansFound = false; // reiniciar la variable antes de hacer una nueva consulta
      this.activeLoans = 0;

      const url = `${this.urlApi}${this.dni}/${this.codCliente}`;
      try {
        const response = await axios.get(url);
        this.loans = response.data;

        // Comprobar si el cliente tiene bloqueos
        if (this.loans && this.loans.length > 0) {
          this.doestExist = false;

          if (this.loans[0].client.blocks && this.loans[0].client.blocks.length > 0) {
            this.hasBlocks = true;
            // Encontrar el bloqueo con fecha de desbloqueo más reciente
            this.latestBlock = this.loans[0].client.blocks.reduce((acc, block) => {
              const currentDate = new Date();
              const unblockDate = new Date(block.unblockDate);

              if (
                currentDate < unblockDate &&
                (!acc || unblockDate > new Date(acc.unblockDate))
              ) {
                return block;
              }
              return acc;
            }, null);
          }
        } else {
          this.doestExist = true;
        }

        this.loans.forEach((loan) => {
          if (loan.state === 1) {
            this.activeLoans++;
          }
        });

        if (this.loans && this.activeLoans === 0) {
          this.noLoansFound = true; // establecer la variable si no se encontraron préstamos
        }
      } catch (error) {
        this.error = "No se pudo cargar la información";
      }
      this.isLoading = false;
    },
    getPaidFees(payments) {
      if (!payments || !Array.isArray(payments)) {
        return "";
      }
      const paidFees = payments.filter((payment) => payment.state === "PAGO").length;
      const totalFees = payments.length;
      return paidFees + " de " + totalFees;
    },
    submitForm() {
      this.getLoans();
    },
    showPayments(loan) {
      // Ordena los pagos por fecha de vencimiento en orden ascendente
      loan.payments.sort((a, b) => {
        const dateA = new Date(a.expirationDate);
        const dateB = new Date(b.expirationDate);
        return dateA - dateB;
      });

      this.selectedLoan = loan;
      // Comprobamos si el modal se ha montado antes de intentar mostrarlo
      this.$nextTick(() => {
        if (this.$refs.paymentsModal) {
          this.$refs.paymentsModal.classList.add("show");
          this.$refs.paymentsModal.style.display = "block";
          document.body.classList.add("modal-open");
        }
      });
    },

    closePaymentsModal() {
      this.selectedLoan = null;
      this.$refs.paymentsModal.classList.remove("show");
      this.$refs.paymentsModal.style.display = "none";
      document.body.classList.remove("modal-open");
    },
    getDaysElapsed(date) {
      const dueDate = new Date(date);
      const today = new Date();
      const elapsedDays = Math.floor((today - dueDate) / (1000 * 60 * 60 * 24));
      return elapsedDays;
    },
    getDisplayDaysElapsed(payment) {
      const clonedPayment = { ...payment }; // Clonar el objeto payment
      const daysElapsed = this.getDaysElapsed(clonedPayment.expirationDate);

      if (clonedPayment.state === "PAGO") {
        return "-";
      }

      if (daysElapsed < 0 && clonedPayment.state === "PENDIENTE") {
        return "Faltan " + Math.abs(daysElapsed) + " días";
      }

      if (daysElapsed === 0 && clonedPayment.state === "PENDIENTE") {
        return "Vence Hoy";
      }

      if (
        daysElapsed > 0 &&
        (clonedPayment.state === "PENDIENTE" || clonedPayment.state === "MORA")
      ) {
        clonedPayment.state = "MORA"; // Actualizar el estado en el objeto clonado
        return daysElapsed + " Días vencido";
      }

      return "";
    },

    getLabelClass(state) {
      if (state === "PENDIENTE") {
        return "badge bg-warning text-dark";
      } else if (state === "MORA") {
        return "badge bg-danger";
      } else if (state === "PAGO") {
        return "badge bg-success";
      }

      return "";
    },
    getOverduePaymentsCount() {
      let overduePaymentsCount = 0;
      let overduePaymentsSum = 0;
      let totalOverdueDays = 0;

      this.filteredLoans.forEach((loan) => {
        loan.payments.forEach((payment) => {
          const daysElapsed = this.getDaysElapsed(payment.expirationDate);
          if (daysElapsed > 0 && payment.state === "PENDIENTE") {
            overduePaymentsCount++;
            overduePaymentsSum += payment.toPay;
            totalOverdueDays += daysElapsed; // sumamos los días de mora de cada pago
          }
        });
      });
      return {
        count: overduePaymentsCount,
        sum: overduePaymentsSum,
        overdueDays: totalOverdueDays, // retornamos la suma total de días de mora
      };
    },
    titleCase(str) {
      return str
        .toLowerCase()
        .split(" ")
        .map((word) => {
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(" ");
    },
  },
};
</script>
<style>
/* CSS */

#paymentsModal{
    background-color: rgba(0,0,0, 0.5) !important;
}

.table-wrapper {
  max-height: 400px;
  overflow-y: auto;
}

@media (max-width: 768px) {
  .modal-dialog-centered {
    max-width: 90%;
  }
}

@media (max-width: 576px) {
  .modal-dialog-centered {
    max-width: 100%;
    margin: auto;
  }
}

/* Añade esto en tu archivo CSS */
.alert-container {
  display: flex;
}

.alert {
  flex: 1;
  margin: 0 5px;
}
</style>
